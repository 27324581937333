<template>
  <div>
    <div class="top">
      <span class="top-text">合约链证书</span>
    </div>
    <div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="申请租户" min-width="30%">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="20%">
        </el-table-column>
        <el-table-column prop="linkedchain" label="关联的链" min-width="30%">
        </el-table-column>
        <el-table-column prop="time" label="更新时间" min-width="20%">
        </el-table-column>
        <el-table-column prop="operation" label="操作" min-width="20%">
          <template v-slot="scope">
            <!-- <el-button @click="submit(scope.row)" type="text" size="small">
              查看
            </el-button> -->
            <div style="display: flex; justify-content: center">
              <Button
                text="查看"
                type="mini-blue"
                @click="submit(scope.row)"
                style="margin-right: 20px"
              />
              <Button text="重置证书" type="mini-red" />
            </div>
            <!-- <el-button @click="submit(scope.row)" type="text" size="small">
              重置证书
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <Pagination />
    </div>
  </div>
</template>

<script>
import { Pagination, Button } from '@/components/common/Index';

export default {
  components: {
    Pagination,
    Button,
  },
  data() {
    return {
      tableData: [
        {
          name: '张三',
          status: '正常',
          linkedchain: '5345链',
          time: '2020-09-22',
          operation: '',
        },
      ],
    };
  },
  methods: {
    submit(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.top {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 35px;
}

.top-text {
  /* margin: 6px 0; */
  font-size: 24px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 18px;
}

.bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
